const environment = process.env.NODE_ENV || 'development';

const dev = {
  apiUrl: 'http://192.168.1.63:3000',
  testApiUrl: 'http://192.168.1.63:3000',
};

const production = {
  apiUrl: 'https://api-rex.ffa-aero.fr',
  testApiUrl: 'https://api-rex-test.ffa-aero.fr',
};

const getEnvironment = () => {
  switch (environment) {
    case 'development':
      return dev;
    case 'production':
    default:
      return production;
  }
};

export default getEnvironment();
