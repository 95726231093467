import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';
import { useEffect, useState } from 'react';
import { CustomTextInput } from '../common/CustomTextInput';
import { defaultMargin } from '../../styles/styles';
import { CustomButton } from '../common/CustomButton';
import { useRexsOfTheMonth } from '../../hooks/useRexsOfTheMonth';
import { colors } from '../../styles/colors';

interface RexOfTheMonthDialogProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  defaultRexIds: number[];
}

export const RexOfTheMonthDialog: React.FC<RexOfTheMonthDialogProps> = ({
  state,
  defaultRexIds,
}) => {
  const { updateRexsOfTheMonth } = useRexsOfTheMonth();

  const [open, setOpen] = state;
  const [rexIds, setRexIds] = useState(defaultRexIds?.join(','));
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRexIds(defaultRexIds?.join(','));
  }, [defaultRexIds]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{s.dashboard.grids.setRexOfTheMonth.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span style={{ whiteSpace: 'pre-line' }}>
            {s.dashboard.grids.setRexOfTheMonth.subtitle}
          </span>
        </DialogContentText>
        <CustomTextInput
          placeholder="ID1,ID2,ID3,..."
          style={{ marginTop: defaultMargin }}
          state={[rexIds, setRexIds]}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          title={s.common.button.cancel}
          onClick={handleClose}
          style={{
            backgroundColor: 'transparent',
            color: colors.textDark,
          }}
        />
        <CustomButton
          title={s.common.button.save}
          loading={isLoading}
          onClick={() => {
            const ids = rexIds
              .split(',')
              ?.map((id) => (id ? Number(id.trim()) : null))
              ?.filter((id) => id !== null) as number[];
            setIsLoading(true);
            updateRexsOfTheMonth
              .mutateAsync({ rexIds: ids || [] })
              .finally(() => {
                setIsLoading(false);
                handleClose();
              });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
