import { useEffect, useState } from 'react';
import { RexVisibility } from '../types/common/api';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';

export const useRexVisibilities = () => {
  const [rexVisibilities, setRexVisibilities] = useState<RexVisibility[]>();

  useEffect(() => {
    api
      .get(`/rex-visibility`)
      .then(({ data }: AxiosResponse<RexVisibility[], any>) => {
        const sortedData = data.reverse();
        setRexVisibilities(sortedData);
      });
  }, []);

  return { rexVisibilities };
};
