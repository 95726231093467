import React, { useEffect, useMemo, useState } from 'react';
import { colors, palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { CustomModal } from '../common/CustomModal';
import { CustomButton } from '../common/CustomButton';
import { s } from '../../strings/strings';
import { defaultMargin, largerMargin, smallMargin } from '../../styles/styles';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomDropdown } from '../common/CustomDropdown';
import { RexVisibility } from '../../types/common/api';

interface ConfirmRexVisibilityModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  visibiliyState: [
    number | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>
  ];
  rexVisibilities: RexVisibility[] | undefined;
}

export const ConfirmRexVisibilityModal: React.FC<
  ConfirmRexVisibilityModalProps
> = ({ open, onClose, onSubmit, visibiliyState: state, rexVisibilities }) => {
  const { primaryColors } = useMainData();
  const [rexVisibility, setRexVisibility] = state;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setError('');
  }, [rexVisibility]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      <CustomModal open={open} onClose={onClose} style={{ minWidth: 600 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              ...fonts.semiBold18,
              color: palette.gray800,
              textAlign: 'center',
              marginTop: 0,
            }}
          >
            {s.rex.newRex.confirmVisibility.title}
          </p>
          <div
            style={{
              paddingLeft: largerMargin,
              paddingRight: largerMargin,
              width: `calc(100% - ${largerMargin + largerMargin}px)`,
            }}
          >
            <CustomDropdown
              label={s.rex.newRex.metaData.rexVisibility}
              placeholder={s.rex.newRex.placeholders.selectValue}
              state={[rexVisibility, setRexVisibility]}
              values={
                rexVisibilities?.map((visibility) => {
                  return {
                    value: visibility.id,
                    label: `${visibility.visibility} - ${visibility.name}`,
                  };
                }) || []
              }
            />
            <p
              style={{
                ...fonts.regular14,
                color: colors.error,
                marginTop: smallMargin,
                marginBottom: 0,
              }}
            >
              {error}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: defaultMargin + smallMargin,
              alignSelf: 'flex-end',
            }}
          >
            <CustomButton
              title={s.common.button.cancel}
              onClick={onClose}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: 'transparent',
                color: palette.gray600,
              }}
            />
            <CustomButton
              title={s.common.button.confirm}
              onClick={() => {
                if (!rexVisibility) {
                  setError(s.rex.newRex.confirmVisibility.needValue);
                  return;
                }

                setLoading(true);
                onSubmit();
              }}
              loading={loading}
              style={{
                marginRight: defaultMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                backgroundColor: primaryColors.primary,
                color: palette.white,
              }}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
