/* eslint-disable react-hooks/exhaustive-deps */
import { BasicPage } from '../../../components/templates/BasicPage';
import { s } from '../../../strings/strings';
import { palette } from '../../../styles/colors';
import { fonts } from '../../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../../styles/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomButton } from '../../../components/common/CustomButton';
import { CustomTextInput } from '../../../components/common/CustomTextInput';
import { Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  activityTypes,
  cresagPlaces,
  entities,
  eventStatus as eventStatusList,
} from '../../../constants';
import { CustomDropdown } from '../../../components/common/CustomDropdown';
import { Cresag, Rex, UpdateCresagDto } from '../../../types/common/api';
import { AxiosResponse } from 'axios';
import { api } from '../../../utils/api';
import { useAuth } from '../../../providers/AuthProvider';
import { CresagPublicationDialog } from '../../../components/dialog/CresagPublicationDialog';

export const EditOrCreateCresagPage = () => {
  const { cresagId } = useParams();
  const navigate = useNavigate();
  const { myEntities } = useAuth();

  const [cresag, setCresag] = useState<Cresag>();
  const [rex, setRex] = useState<Rex>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [activityType, setActivityType] = useState<string | undefined>('');
  const [eventStatus, setEventStatus] = useState<string | undefined>(
    eventStatusList[0]
  );
  const [informedEntities, setInformedEntities] = useState<
    string[] | undefined
  >(['BEA']);

  const [eventTime, setEventTime] = useState<string | undefined>('');
  const [eventZone, setEventZone] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');

  const [callSign, setCallSign] = useState<string | undefined>('');
  const [exploiting, setExploiting] = useState<string | undefined>('');
  const [departureAerodrome, setDepartureAerodrome] = useState<
    string | undefined
  >('');
  const [destination, setDestination] = useState<string | undefined>('');

  const [correctiveActions, setCorrectiveActions] = useState<
    string | undefined
  >('');
  const [conclusions, setConclusions] = useState<string | undefined>('');
  const [errorFields, setErrorFields] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [openPublicationDialog, setOpenPublicationDialog] =
    useState<boolean>(false);

  useEffect(() => {
    api
      .get(`cresag/${cresagId}`)
      .then(({ data }: AxiosResponse<Cresag, any>) => setCresag(data));
  }, [cresagId]);

  useEffect(() => {
    if (cresag) {
      const rex = cresag?.rex;
      setActivityType(cresag?.activity_type);
      setEventStatus(cresag?.event_status);
      setEventTime(cresag?.event_time || '');
      setInformedEntities(cresag?.informed_entities?.split(',') || ['BEA']);
      setEventZone(cresag?.event_zone);
      setDescription(cresag?.description || rex?.event_summary);
      setCallSign(cresag?.call_sign);
      setDepartureAerodrome(cresag?.departure_aerodrome || rex?.place);
      setDestination(cresag?.destination || rex?.place);
      setCorrectiveActions(
        cresag?.corrective_actions ||
          rex?.actions?.map((action) => '- ' + action?.description)?.join('\n')
      );
      setConclusions(cresag?.conclusions || rex?.safety_education);
    }
    setRex(cresag?.rex);
  }, [cresag]);

  useEffect(() => {
    const entity = myEntities?.find(
      (entity) => entity.ree_idx?.toString() === rex?.club_id
    );
    setExploiting(cresag?.exploiting || entity?.ree_designation);
  }, [cresag, myEntities, rex?.club_id]);

  useEffect(() => {
    if (errorFields?.eventStatus)
      setErrorFields({ ...errorFields, eventStatus: false });
  }, [eventStatus]);

  useEffect(() => {
    if (errorFields?.description)
      setErrorFields({ ...errorFields, description: false });
  }, [description]);

  useEffect(() => {
    if (errorFields?.exploiting)
      setErrorFields({ ...errorFields, exploiting: false });
  }, [exploiting]);

  if (!cresagId || !cresag?.rex_id || !rex) return <></>;

  const validateForm = () => {
    const fields: any = {};

    fields.rexTitle = !!rex?.title;
    fields.eventKind = !!rex?.event_kind;
    fields.eventType = !!rex?.event_type;
    fields.eventDate = !!rex?.event_date;
    fields.aircraftRegistration = !!rex?.aircraft_registration;
    fields.exploiting = !!exploiting;
    fields.flightPhase = !!rex?.flight_phase_id;
    fields.place = !!rex?.place;
    fields.flightType = !!rex?.flight_type;
    fields.description = !!description;
    fields.eventStatus = !!eventStatus;

    const errorFields = Object.keys(fields).reduce((acc, key) => {
      acc[key] = !fields[key];
      return acc;
    }, {} as { [key: string]: boolean });

    setErrorFields(errorFields);
    return fields;
  };

  const getErrorLabel = (field: string) => {
    switch (field) {
      case 'rexTitle':
        return s.rex.newRex.form.title;
      case 'eventKind':
        return s.rex.newRex.generalInformation.eventKind;
      case 'eventType':
        return s.rex.newRex.generalInformation.eventType;
      case 'eventDate':
        return s.rex.newRex.generalInformation.eventDate;
      case 'aircraftRegistration':
        return s.rex.newRex.generalInformation.aircraftRegistration;
      case 'flightPhase':
        return s.rex.newRex.generalInformation.flightPhase;
      case 'place':
        return s.rex.newRex.generalInformation.place;
      case 'flightType':
        return s.rex.newRex.generalInformation.flightType;
      case 'exploiting':
        return s.rex.newCresag.aircraftSection.exploiting;
      case 'description':
        return s.rex.newCresag.descriptionSection.description;
      case 'eventStatus':
        return s.rex.newCresag.descriptionSection.eventStatus;
      default:
        return field[0];
    }
  };

  const onSubmitCresag = async () => {
    const errorFields = Object.entries(validateForm())?.filter(
      (field) => !field[1]
    );

    if (errorFields.length > 0) {
      const cresagFields = ['eventStatus', 'description', 'exploiting'];
      const errorMessageCresag = errorFields
        ?.filter((field) => cresagFields.includes(field[0]))
        ?.map((field) => getErrorLabel(field[0]))
        ?.join(', ');
      const errorMessageRex = errorFields
        ?.filter((field) => !cresagFields.includes(field[0]))
        ?.map((field) => getErrorLabel(field[0]))
        ?.join(', ');
      const errorMessage = `Veuillez renseigner les champs suivants pour pouvoir soumettre le CRESAG : ${
        errorMessageCresag
          ? `\n\nFormulaire CRESAG : ${errorMessageCresag}`
          : ''
      }${errorMessageRex ? `\n\nFormulaire REX : ${errorMessageRex}` : ''}`;
      alert(errorMessage);
      return;
    }

    setIsSending(true);
    await handleSave(false).finally(() => {
      setIsSending(false);
    });
  };
  const onSaveCresag = async () => {
    setIsSaving(true);
    await handleSave(true).finally(() => {
      setIsSaving(false);
    });
  };

  const handleSave = async (draft = false) => {
    const data: UpdateCresagDto = {
      activity_type: activityType,
      event_status: eventStatus,
      event_time: eventTime,
      informed_entities: informedEntities?.join(', '),
      event_zone: eventZone,
      description: description,
      call_sign: callSign,
      exploiting: exploiting,
      departure_aerodrome: departureAerodrome,
      destination: destination,
      corrective_actions: correctiveActions,
      conclusions: conclusions,
    };

    return await api
      .put(`cresag/${cresagId}${draft ? '?draft=true' : ''}`, data)
      .then(({ data: cresag }) => {
        if (!draft) {
          setCresag(cresag);
          setOpenPublicationDialog(true);
          return;
        }
        const routeToRedirect = !cresag?.rex?.publication_date
          ? `/rex/complete/${cresag?.rex_id}`
          : `/rex/${cresag?.rex_id}`;
        navigate(routeToRedirect);
      });
  };

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p style={{ ...fonts.bold24, margin: 0 }}>
              {s.rex.newCresag.title}
            </p>
            <p style={{ ...fonts.medium16, margin: 0, marginTop: smallMargin }}>
              {s.rex.newCresag.subtitle(cresag.rex_id)}
            </p>
          </div>
          <CustomButton
            title={s.rex.newCresag.returnToAssociatedRex}
            onClick={() => {
              navigate(`/rex/complete/${cresag?.rex_id}`);
              // setIsRexHistoryOpen(true);
            }}
            style={{
              ...fonts.medium14,
              color: palette.darkBlue600,
              backgroundColor: palette.white,
              border: `1px solid ${palette.darkBlue600}`,
              flexGrow: 0,
            }}
            iconPosition="right"
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: largeMargin,
            marginLeft: largerMargin * 3,
            marginRight: largerMargin * 3,
          }}
        >
          <div
            style={{
              padding: largeMargin,
              border: `1px solid ${palette.gray200}`,
              borderRadius: mediumRadius,
              flex: 1,
            }}
          >
            <Section title={s.rex.newCresag.descriptionSection.title}>
              <Subtitle title={s.rex.newCresag.descriptionSection.general} />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomDropdown
                    label={s.rex.newCresag.descriptionSection.activityType}
                    placeholder={s.common.placeholders.selectValue}
                    state={[activityType, setActivityType]}
                    values={activityTypes.map((activityType) => ({
                      value: activityType,
                      label: activityType,
                    }))}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}></Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.descriptionSection.rexTitle + '*'}
                    value={rex?.title || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.descriptionSection.eventClass + '*'}
                    value={rex?.event_kind || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomDropdown
                    label={s.rex.newCresag.descriptionSection.eventStatus + '*'}
                    placeholder={s.common.placeholders.selectValue}
                    state={[eventStatus, setEventStatus]}
                    values={eventStatusList.map((status, index) => ({
                      value: status,
                      label: status,
                    }))}
                    isError={!!errorFields?.eventStatus}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomDropdown
                    label={s.rex.newCresag.descriptionSection.informedEntities}
                    placeholder={s.common.placeholders.selectValue}
                    alwaysCheckedValues={['BEA']}
                    state={[informedEntities, setInformedEntities]}
                    disabled
                    values={entities.map((entity, index) => ({
                      value: entity,
                      label: entity,
                    }))}
                    multiple
                  />
                </Grid>
              </Grid>
              <Subtitle
                title={s.rex.newCresag.descriptionSection.dateAndPlace}
              />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.descriptionSection.date + '*'}
                    value={rex?.event_date || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.descriptionSection.hour}
                    type="time"
                    state={[eventTime, setEventTime]}
                  />
                </Grid>
                <Grid item xs={12} style={styles.gridItem}>
                  <CustomDropdown
                    label={s.rex.newCresag.descriptionSection.eventZone}
                    placeholder={s.common.placeholders.selectValue}
                    state={[eventZone, setEventZone]}
                    values={cresagPlaces.map((place, index) => ({
                      value: place,
                      label: place,
                    }))}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.descriptionSection.oaciCode + '*'}
                    value={rex?.place || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.descriptionSection.details}
                    value={rex?.place_description || ''}
                  />
                </Grid>
              </Grid>
              <Subtitle
                title={s.rex.newCresag.descriptionSection.descriptionAndContext}
              />
              <CustomTextInput
                label={s.rex.newCresag.descriptionSection.description + '*'}
                placeholder={s.rex.newCresag.descriptionSection.description}
                multiline
                numberOfLines={3}
                state={[description, setDescription]}
                style={{ marginTop: smallMargin }}
                isError={!!errorFields?.description}
              />
            </Section>
            <Divider
              style={{
                backgroundColor: palette.gray200,
                marginTop: largeMargin,
                marginBottom: largeMargin,
              }}
            />
            <Section title={s.rex.newCresag.aircraftSection.title}>
              <Subtitle
                title={s.rex.newCresag.aircraftSection.basicInformation}
              />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.aircraftSection.aircraftModel}
                    value={rex?.aircraft_type || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.aircraftSection.registration + '*'}
                    value={rex?.aircraft_registration || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.aircraftSection.radioCallSign}
                    placeholder={s.rex.newCresag.aircraftSection.radioCallSign}
                    state={[callSign, setCallSign]}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.aircraftSection.exploiting + '*'}
                    placeholder={s.common.placeholders.clubName}
                    state={[exploiting, setExploiting]}
                    isError={!!errorFields?.exploiting}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.aircraftSection.flightPhase + '*'}
                    value={rex?.flight_phase?.name || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.aircraftSection.flightKind}
                    value={rex?.flight_kind || ''}
                  />
                </Grid>
              </Grid>
              <Subtitle
                title={
                  s.rex.newCresag.aircraftSection.additionalAircraftInformation
                }
              />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.aircraftSection.departureAerodrome}
                    placeholder={
                      s.rex.newCresag.aircraftSection.departureAerodrome
                    }
                    state={[departureAerodrome, setDepartureAerodrome]}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.aircraftSection.destination}
                    placeholder={s.rex.newCresag.aircraftSection.destination}
                    state={[destination, setDestination]}
                  />
                </Grid>
              </Grid>
            </Section>
            <Divider
              style={{
                backgroundColor: palette.gray200,
                marginTop: largeMargin,
                marginBottom: largeMargin,
              }}
            />
            <Section title={s.rex.newCresag.analysisSection.title}>
              <Subtitle title={s.rex.newCresag.analysisSection.event} />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <NonEditableField
                    label={s.rex.newCresag.analysisSection.eventType + '*'}
                    value={rex?.event_type || ''}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}></Grid>
              </Grid>
              <Subtitle
                title={s.rex.newCresag.analysisSection.analysisResult}
              />
              <Grid
                container
                rowSpacing={`${smallMargin + tinyMargin}px`}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  marginTop: 0,
                }}
              >
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.analysisSection.conclusions}
                    placeholder={s.rex.newCresag.analysisSection.conclusions}
                    multiline
                    numberOfLines={6}
                    state={[conclusions, setConclusions]}
                  />
                </Grid>
                <Grid item xs={6} style={styles.gridItem}>
                  <CustomTextInput
                    label={s.rex.newCresag.analysisSection.correctiveActions}
                    placeholder={
                      s.rex.newCresag.analysisSection.correctiveActions
                    }
                    multiline
                    numberOfLines={6}
                    state={[correctiveActions, setCorrectiveActions]}
                  />
                </Grid>
              </Grid>
            </Section>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: largeMargin,
              }}
            >
              <CustomButton
                title={s.common.button.sendCresag}
                onClick={onSubmitCresag}
                style={{
                  marginRight: 'auto',
                  backgroundColor: palette.violet600,
                  color: palette.white,
                }}
                loading={isSending}
              />
              <CustomButton
                title={s.common.button.cancel}
                onClick={() => navigate(-1)}
                style={{
                  marginRight: largeMargin,
                  backgroundColor: 'transparent',
                  color: palette.gray600,
                }}
              />
              <CustomButton
                title={s.common.button.saveUpdates}
                onClick={onSaveCresag}
                loading={isSaving}
              />
            </div>
          </div>
        </div>
      </BasicPage>
      <CresagPublicationDialog
        state={[openPublicationDialog, setOpenPublicationDialog]}
        onClose={() => {
          setOpenPublicationDialog(false);
          const routeToRedirect = !cresag?.rex?.publication_date
            ? `/rex/complete/${cresag?.rex_id}`
            : `/rex/${cresag?.rex_id}`;
          navigate(routeToRedirect);
        }}
      />
    </>
  );
};

interface SectionProps {
  title: string;
  children?: any;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <>
      <p style={{ ...fonts.bold18, margin: 0 }}>{title}</p>
      <div
        style={{
          marginTop: defaultMargin,
          marginLeft: smallMargin,
          marginRight: smallMargin,
        }}
      >
        {children}
      </div>
    </>
  );
};

interface SubtitleProps {
  title: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ title }) => {
  return (
    <>
      <p
        style={{
          margin: 0,
          marginTop: defaultMargin,
          ...fonts.regular18,
          color: palette.gray500,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </p>
    </>
  );
};

interface NonEditableFieldProps {
  label: string;
  value: string;
}

const NonEditableField: React.FC<NonEditableFieldProps> = ({
  label,
  value,
}) => {
  return (
    <>
      <div
        style={{
          borderRadius: mediumRadius,
          backgroundColor: palette.gray100,
          flex: 1,
          padding: smallMargin + tinyMargin,
        }}
      >
        <p
          style={{
            margin: 0,
            ...fonts.regular14,
            color: palette.gray700,
          }}
        >
          {label}
        </p>
        <p
          style={{
            margin: 0,
            marginTop: tinyMargin,
            ...fonts.regular16,
          }}
        >
          {value}
        </p>
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};
