import React, { useMemo, useState } from 'react';
import { s } from '../../../strings/strings';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { useNavigate } from 'react-router-dom';
import { CustomTooltip } from '../../common/CustomTooltip';
import { smallMargin } from '../../../styles/styles';
import classicPenIcon from '../../../assets/icons/classic-pen.svg';
import { RexOfTheMonthDialog } from '../../dialog/RexOfTheMonthDialog';
import { useRexsOfTheMonth } from '../../../hooks/useRexsOfTheMonth';

interface DashboardOutstandingREXOfTheMonthProps {
  style?: React.CSSProperties;
}

export const DashboardOutstandingREXOfTheMonth: React.FC<
  DashboardOutstandingREXOfTheMonthProps
> = ({ style }) => {
  const navigate = useNavigate();
  const { rexsOfTheMonth } = useRexsOfTheMonth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 5,
    },
    {
      field: 'description',
      headerName: s.common.grid.description,
      flex: 5,
    },
  ];

  const rexIds = useMemo(
    () => rexsOfTheMonth?.rexs?.map((rex) => rex.id),
    [rexsOfTheMonth]
  );

  return (
    <>
      <div style={{ ...style }}>
        <CustomGrid
          headerTitle={s.dashboard.grids.outstandingRexOfTheMonth}
          headerRightComponent={
            rexsOfTheMonth?.canEdit && (
              <CustomTooltip text={s.common.tooltip.edit}>
                <img
                  src={classicPenIcon}
                  alt="Edit icon"
                  onClick={(e) => {
                    setIsDialogOpen(true);
                    e.stopPropagation();
                  }}
                  style={{ cursor: 'pointer', padding: smallMargin }}
                />
              </CustomTooltip>
            )
          }
          rows={rexsOfTheMonth?.rexs || []}
          columns={columns}
          onRowClick={(row) => navigate('/rex/' + row.id)}
        />
        <RexOfTheMonthDialog
          state={[isDialogOpen, setIsDialogOpen]}
          defaultRexIds={rexIds || []}
        />
      </div>
    </>
  );
};
