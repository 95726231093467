import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { s } from '../../strings/strings';
import React from 'react';

interface CresagPublicationDialogProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onClose?: () => void;
}

export const CresagPublicationDialog: React.FC<
  CresagPublicationDialogProps
> = ({ state, onClose }) => {
  const [open, setOpen] = state;

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>{s.cresagPublicationDialog.publishedTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {s.cresagPublicationDialog.publishedContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {s.common.button.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
