import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../providers/AuthProvider';
import { api } from '../utils/api';
import { Rex } from '../types/common/api';
import { AxiosResponse } from 'axios';
import { useMainData } from '../providers/MainDataProvider';

interface RexsOfTheMonth {
  rexs: Rex[];
  canEdit: boolean;
}

export const useRexsOfTheMonth = () => {
  const { isTestEnvironment } = useMainData();
  const { user } = useAuth();

  const rexsOfTheMonthKey = [
    'rexsOfTheMonthKey',
    user?.id,
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: rexsOfTheMonth, refetch: refetchRexsOfTheMonth } = useQuery(
    rexsOfTheMonthKey,
    async () =>
      await api
        .get('rex/rex-of-the-month')
        .then(({ data }: AxiosResponse<RexsOfTheMonth, any>) => data)
  );

  const updateRexsOfTheMonth = useMutation(
    async ({ rexIds }: { rexIds: number[] }) =>
      api.put(`rex/rex-of-the-month`, { rex_ids: rexIds }, {}),
    {
      onSuccess: ({
        data: updatedRexs,
      }: AxiosResponse<RexsOfTheMonth, any>) => {
        // Update local data
        queryClient.setQueryData(rexsOfTheMonthKey, updatedRexs);
      },
    }
  );

  return {
    rexsOfTheMonth,
    refetchRexsOfTheMonth,
    updateRexsOfTheMonth,
  };
};
