import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  mediumRadius,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { CustomTextInput } from '../common/CustomTextInput';
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Grid, Stack } from '@mui/material';
import { CustomButton } from '../common/CustomButton';
import { CustomRiskMatrix } from '../common/CustomRiskMatrix';
import { CustomCauseOrActionSelector } from '../common/CustomCauseOrActionSelector';
import { CustomDropdown } from '../common/CustomDropdown';
import { CauseData, RexDraft } from '../../types/common/api';
import { useRexsToCompleteMutations } from '../../hooks/useRexsToCompleteMutations';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useFlightPhases } from '../../hooks/useFlightPhases';
import { useReportSubjects } from '../../hooks/useReportSubjects';
import { RexTypeSelector } from './RexTypeSelector';
import { AddCauseModal } from './AddCauseModal';
import { AddActionModal } from './AddActionModal';
import fileUploadFrame from '../../assets/icons/file-upload-frame.svg';
import greenFileUploadFrame from '../../assets/icons/green-file-upload-frame.svg';
import {
  eventKinds,
  eventTypes,
  flightKinds,
  flightRegimes,
  flightTypes,
} from '../../constants';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { dateToString } from '../../utils/date';
import { Entity, ReportType, RexStatusList } from '../../types/common/main';
import { useRexVisibilities } from '../../hooks/useRexVisibilities';
import { ImageSlot } from '../common/ImageSlot';
import { useImagePicker } from '../../hooks/useImagePicker';
import { api } from '../../utils/api';
import { CresagIdentificationModal } from './CresagIdentificationModal';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { CustomAutoComplete } from '../common/CustomAutoComplete';
import environment from '../../environment';
import { useMainData } from '../../providers/MainDataProvider';
import { ConfirmRexVisibilityModal } from './ConfirmRexVisibilityModal';
import flightPhasesInfo from '../../assets/images/flightPhase/flight-phases.png';
import eventTypeInfo1 from '../../assets/images/eventType/event-type-1.png';
import eventTypeInfo2 from '../../assets/images/eventType/event-type-2.png';
import eventTypeInfo3 from '../../assets/images/eventType/event-type-3.png';
import eventTypeInfo4 from '../../assets/images/eventType/event-type-4.png';
import eventTypeInfo5 from '../../assets/images/eventType/event-type-5.png';
import eventKindInfo from '../../assets/images/eventKind/event-kind.png';

interface EditOrCreateRexProps {
  initialRexData?: RexDraft;
}

export const EditOrCreateRex: React.FC<EditOrCreateRexProps> = ({
  initialRexData,
}) => {
  const { isTestEnvironment } = useMainData();
  const { user, hasCPSPermission, clubsWithCPSAccess } = useAuth();
  const { createRexToComplete, updateRexToComplete, reopenRex, completeRex } =
    useRexsToCompleteMutations();
  const navigate = useNavigate();
  const { flightPhases } = useFlightPhases();
  const { reportSubjects } = useReportSubjects();
  const { rexVisibilities } = useRexVisibilities();
  const {
    handleFileChange,
    handleRemoveFile,
    selectedFiles,
    setSelectedFiles,
  } = useImagePicker();

  const [isCresagIdentificationModalOpen, setIsCresagIdentificationModalOpen] =
    useState(false);
  const [isConfirmRexVisibilityModalOpen, setIsConfirmRexVisibilityModalOpen] =
    useState(false);
  const [isAddCauseModalOpen, setIsAddCauseModalOpen] = useState(false);
  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false);

  const [title, setTitle] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');
  const [entityIndex, setEntityIndex] = useState<string | undefined>('');

  // General information
  const [rexType, setRexType] = useState<string>(ReportType.Anomaly);
  const [rexSubject, setRexSubject] = useState<number>();
  const [eventDate, setEventDate] = useState<string | undefined>('');
  const [flightPhase, setFlightPhase] = useState<number>();
  const [place, setPlace] = useState<string | undefined>('');
  const [placeDescription, setPlaceDescription] = useState<string | undefined>(
    ''
  );
  const [flightKind, setFlightKind] = useState<string | undefined>('');
  const [flightType, setFlightType] = useState<string | undefined>('');
  const [flightRegime, setFlightRegime] = useState<string | undefined>('');
  const [aircraftType, setAircraftType] = useState<string | undefined>('');
  const [aircraftRegistration, setAircraftRegistration] = useState<
    string | undefined
  >('');
  const [eventType, setEventType] = useState<string | undefined>('');
  const [eventKind, setEventKind] = useState<string | undefined>('');

  // CPS Analysis
  const [eventSummary, setEventSummary] = useState<string | undefined>('');
  const [actionSelectionModel, setActionSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [causeSelectionModel, setCauseSelectionModel] = useState<CauseData[]>(
    []
  );
  // const [causeSelectionData, setCauseSelectionData] = useState<CauseData[]>([]);
  const [safetyEducation, setSafetyEducation] = useState<string | undefined>(
    ''
  );
  const [initialRiskSeverity, setInitialRiskSeverity] = useState<number>();
  const [initialRiskProbability, setInitialRiskProbability] =
    useState<number>();
  const [residualRiskSeverity, setResidualRiskSeverity] = useState<number>();
  const [residualRiskProbability, setResidualRiskProbability] =
    useState<number>();

  // Meta data
  const [clubConcerned, setClubConcerned] = useState<string | undefined>('');
  const [cpsConcerned, setCpsConcerned] = useState<string | undefined>(
    user?.name || ''
  );
  const [declarer, setDeclarer] = useState<string | undefined>('');
  const [rexVisibility, setRexVisibility] = useState<number>();

  const [completeRexLoading, setCompleteRexLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const cresagModalSubmitAction = useRef<'submit' | 'save'>('submit');

  const { data: entityData } = useQuery(
    ['entity', entityIndex, ...(isTestEnvironment ? ['test'] : [])],
    async () =>
      await api
        .get(`smile/entity/${entityIndex}`)
        .then(({ data }: AxiosResponse<Entity, any>) => data),
    {
      enabled: !!entityIndex,
      staleTime: 1000 * 60,
    }
  );

  useEffect(() => {
    if (!rexVisibilities) return;
    setRexVisibility(rexVisibilities.at(0)?.id);
  }, [rexVisibilities]);

  useEffect(() => {
    if (initialRexData) {
      setTitle(initialRexData.title || '');
      setDescription(initialRexData.description || '');
      setEntityIndex(
        initialRexData?.club_id || initialRexData?.report?.club_id || ''
      );

      // General information
      if (initialRexData.rex_type) setRexType(initialRexData.rex_type);
      if (initialRexData.rex_subject_id !== undefined)
        setRexSubject(initialRexData.rex_subject_id);
      setEventDate(initialRexData.event_date || '');
      if (initialRexData.flight_phase_id !== undefined)
        setFlightPhase(initialRexData.flight_phase_id);
      setPlace(initialRexData.place || '');
      setPlaceDescription(initialRexData.place_description || '');
      setFlightRegime(initialRexData.flight_regime || '');
      setFlightKind(initialRexData.flight_kind || '');
      setFlightType(initialRexData.flight_type || '');
      setAircraftType(initialRexData.aircraft_type || '');
      setAircraftRegistration(initialRexData.aircraft_registration || '');
      setEventType(initialRexData.event_type || '');
      setEventKind(initialRexData.event_kind || '');

      // CPS Analysis
      setEventSummary(initialRexData.event_summary || '');
      if (initialRexData.actions)
        setActionSelectionModel(initialRexData.actions?.map((e) => e.id));
      if (initialRexData.causes)
        setCauseSelectionModel(
          initialRexData.causes?.map((e) => ({
            id: e.cause_id,
            type: e.cause_type,
          }))
        );
      setSafetyEducation(initialRexData.safety_education || '');
      if (initialRexData.initial_risk_severity !== undefined)
        setInitialRiskSeverity(initialRexData.initial_risk_severity);
      if (initialRexData.initial_risk_probability !== undefined)
        setInitialRiskProbability(initialRexData.initial_risk_probability);
      if (initialRexData.residual_risk_severity !== undefined)
        setResidualRiskSeverity(initialRexData.residual_risk_severity);
      if (initialRexData.residual_risk_probability !== undefined)
        setResidualRiskProbability(initialRexData.residual_risk_probability);

      // Meta data
      setCpsConcerned(`${initialRexData.updated_by?.name || user?.name}`);
      if (initialRexData.report?.sender_name)
        setDeclarer(`${initialRexData.report?.sender_name}`);
      if (initialRexData.report?.club_name)
        setClubConcerned(`${initialRexData.report?.club_name}`);
      if (initialRexData.rex_visibility_id)
        setRexVisibility(initialRexData.rex_visibility_id);
    }
  }, [initialRexData, user]);

  useEffect(() => {
    if (initialRexData?.images) {
      setSelectedFiles(
        initialRexData?.images?.map((elem) => ({
          uri: `${elem.uri}`,
        }))
      );
    } else if (initialRexData?.report?.images) {
      setSelectedFiles(
        initialRexData?.report?.images?.map((elem) => ({
          uri: elem.path,
        }))
      );
    }
  }, [initialRexData, setSelectedFiles]);

  useEffect(() => {
    if (rexType === ReportType.GoodPractice) {
      setInitialRiskSeverity(undefined);
      setInitialRiskProbability(undefined);
      setResidualRiskSeverity(undefined);
      setResidualRiskProbability(undefined);
    }
  }, [rexType]);

  const onSubmit = () => {
    const rex: RexDraft = {
      title,
      description,
      club_id: entityIndex,
      rex_type: rexType,
      rex_subject_id: rexSubject,
      event_date: eventDate,
      flight_phase_id: flightPhase,
      place,
      place_description: placeDescription,
      flight_kind: flightKind,
      flight_type: flightType,
      flight_regime: flightRegime,
      aircraft_type: aircraftType,
      aircraft_registration: aircraftRegistration,
      event_type: eventType,
      event_kind: eventKind,
      event_summary: eventSummary,
      safety_education: safetyEducation,
      initial_risk_severity: initialRiskSeverity,
      initial_risk_probability: initialRiskProbability,
      residual_risk_severity: residualRiskSeverity,
      residual_risk_probability: residualRiskProbability,
      rex_visibility_id: rexVisibility,
      causes_data: causeSelectionModel,
      actions_ids: actionSelectionModel?.map((e) => Number(e)),
      report_id: initialRexData?.report_id,
      images: selectedFiles,
    };

    if (initialRexData?.id) {
      return updateRexToComplete
        .mutateAsync({
          id: initialRexData.id,
          data: rex,
        })
        .then(({ data }) => {
          return data.id;
        });
    } else {
      return createRexToComplete.mutateAsync(rex).then(({ data }) => {
        return data.id;
      });
    }
  };

  useEffect(() => {
    if (clubsWithCPSAccess?.length === 1) {
      const firstClub = clubsWithCPSAccess?.at(0);
      if (firstClub?.ree_idx) setEntityIndex(firstClub.ree_idx.toString());
    }
  }, [clubsWithCPSAccess]);

  useEffect(() => {
    const aircraft = entityData?.avion?.find(
      (avion) => avion?.av_immat === aircraftRegistration
    );
    setAircraftType(
      aircraft
        ? `${aircraft?.av_constructeur}${
            aircraft?.av_modele ? ` - ${aircraft?.av_modele}` : ''
          }`
        : ''
    );
  }, [aircraftRegistration, entityData]);

  if (!hasCPSPermission) {
    navigate('/dashboard');
    return null;
  }

  const mediaBaseUrl = isTestEnvironment
    ? environment.testApiUrl
    : environment.apiUrl;

  return (
    <>
      <div
        style={{
          padding: largeMargin,
          border: `1px solid ${palette.gray200}`,
          borderRadius: mediumRadius,
        }}
      >
        <CustomTextInput
          label={s.rex.newRex.form.title}
          placeholder={s.rex.newRex.form.titlePlaceholder}
          state={[title, setTitle]}
          style={{ marginBottom: defaultMargin }}
        />
        <CustomTextInput
          label={s.rex.newRex.form.description}
          placeholder={s.rex.newRex.form.description}
          multiline
          numberOfLines={2}
          maxLength={255}
          state={[description, setDescription]}
          style={{ marginBottom: defaultMargin }}
        />
        <CustomDropdown
          label={s.rex.newRex.metaData.clubConcerned}
          placeholder={s.rex.newRex.placeholders.club}
          state={[entityIndex, setEntityIndex]}
          values={
            clubsWithCPSAccess?.map((club) => {
              return {
                value: club.ree_idx,
                label: club.ree_designation,
              };
            }) || []
          }
        />
        <Divider
          style={{
            backgroundColor: palette.gray200,
            marginTop: largeMargin,
            marginBottom: largeMargin,
          }}
        />
        {/* General information */}
        <Section title={s.rex.newRex.generalInformation.title}>
          <Stack direction="row" spacing={`${defaultMargin}px`}>
            {selectedFiles && selectedFiles.length > 0 ? (
              Array.from({ length: selectedFiles.length }).map((_, index) => {
                const uri = selectedFiles?.at(index)?.uri?.replace(/\\/g, '/');
                const fullUri = `${mediaBaseUrl}/${uri}`;

                return (
                  <ImageSlot
                    key={index}
                    uri={fullUri}
                    onDelete={() => handleRemoveFile(index)}
                    handleFileChange={handleFileChange}
                  />
                );
              })
            ) : (
              <>
                <>
                  <label htmlFor="image-picker">
                    <img
                      src={
                        isTestEnvironment
                          ? greenFileUploadFrame
                          : fileUploadFrame
                      }
                      alt="placeholder"
                      // onClick={onClick}
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                  <input
                    id="image-picker"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </>
              </>
            )}
          </Stack>
          {initialRexData?.report?.voice_recording && (
            <div
              style={{
                display: 'flex',
                marginTop: defaultMargin,
                flexDirection: 'column',
                columnGap: smallMargin,
                marginBottom: tinyMargin,
              }}
            >
              <p
                style={{
                  ...fonts.medium14,
                  color: palette.gray700,
                  margin: 0,
                  marginBottom: tinyMargin,
                }}
              >
                {s.rex.newRex.generalInformation.audioRecord}
              </p>
              <audio
                controls
                src={`${mediaBaseUrl}/${initialRexData?.report?.voice_recording?.path?.replace(
                  /\\/g,
                  '/'
                )}`}
              />
            </div>
          )}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ marginTop: defaultMargin }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <RexTypeSelector state={[rexType, setRexType]} />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.rexSubject}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[rexSubject, setRexSubject]}
                values={
                  reportSubjects?.map((subject) => {
                    return {
                      value: subject.id,
                      label: subject.name,
                    };
                  }) || []
                }
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                label={s.rex.newRex.generalInformation.eventDate}
                type="date"
                state={[eventDate, setEventDate]}
                max={new Date().toISOString().split('T')[0]}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.flightPhase}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[flightPhase, setFlightPhase]}
                values={
                  flightPhases?.map((flightPhase) => {
                    return {
                      value: flightPhase.id,
                      label: `${flightPhase?.id} - ${flightPhase.name}`,
                    };
                  }) || []
                }
                informationpDialogContent={
                  <img
                    src={flightPhasesInfo}
                    alt=""
                    style={{ width: '100%' }}
                  />
                }
              />
            </Grid>
          </Grid>
          <Divider
            style={{
              backgroundColor: palette.gray200,
              marginTop: defaultMargin + smallMargin,
              marginBottom: defaultMargin + smallMargin,
            }}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ alignItems: 'flex-end' }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                label={s.rex.newRex.generalInformation.place}
                placeholder={s.rex.newRex.placeholders.oaciCode}
                state={[place, setPlace]}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                label={s.rex.newRex.generalInformation.placeDescription}
                placeholder={s.rex.newRex.placeholders.description}
                state={[placeDescription, setPlaceDescription]}
                tooltip={
                  s.rex.newRex.generalInformation.tooltip.placeDescription
                }
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.flightKind}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[flightKind, setFlightKind]}
                values={flightKinds.map((flightKind, index) => ({
                  value: flightKind,
                  label: flightKind,
                }))}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.flightType}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[flightType, setFlightType]}
                values={flightTypes.map((flightType, index) => ({
                  value: flightType,
                  label: flightType,
                }))}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.flightRegime}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[flightRegime, setFlightRegime]}
                values={flightRegimes.map((flightRegime, index) => ({
                  value: flightRegime,
                  label: flightRegime,
                }))}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}></Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomAutoComplete
                label={s.rex.newRex.generalInformation.aircraftRegistration}
                placeholder={s.rex.newRex.placeholders.registration}
                state={[aircraftRegistration, setAircraftRegistration]}
                options={Array.from(
                  new Set(
                    entityData?.avion.map((avion) => avion.av_immat) || []
                  )
                )}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                label={s.rex.newRex.generalInformation.aircraftType}
                placeholder={s.rex.newRex.placeholders.type}
                state={[aircraftType, setAircraftType]}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.eventType}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[eventType, setEventType]}
                values={eventTypes.map((eventType, index) => ({
                  value: eventType,
                  label: eventType,
                }))}
                informationpDialogContent={
                  <>
                    <img
                      src={eventTypeInfo1}
                      alt=""
                      style={{ width: '100%' }}
                    />
                    <img
                      src={eventTypeInfo2}
                      alt=""
                      style={{ width: '100%' }}
                    />
                    <img
                      src={eventTypeInfo3}
                      alt=""
                      style={{ width: '100%' }}
                    />
                    <img
                      src={eventTypeInfo4}
                      alt=""
                      style={{ width: '100%' }}
                    />
                    <img
                      src={eventTypeInfo5}
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.generalInformation.eventKind}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[eventKind, setEventKind]}
                values={eventKinds.map((eventKind, index) => ({
                  value: eventKind,
                  label: eventKind,
                }))}
                informationpDialogContent={
                  <img src={eventKindInfo} alt="" style={{ width: '100%' }} />
                }
              />
            </Grid>
          </Grid>
        </Section>
        <Divider
          style={{
            backgroundColor: palette.gray200,
            marginTop: largeMargin,
            marginBottom: largeMargin,
          }}
        />
        {/* CPS Analysis */}
        <Section title={s.rex.newRex.cpsAnalysis.title}>
          <CustomTextInput
            label={s.rex.newRex.cpsAnalysis.eventSummary}
            placeholder={s.rex.newRex.placeholders.summary}
            multiline
            numberOfLines={3}
            state={[eventSummary, setEventSummary]}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{
              marginTop: defaultMargin,
              alignItems:
                causeSelectionModel?.length > 0 &&
                actionSelectionModel?.length > 0
                  ? 'flex-start'
                  : 'center',
            }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <CustomCauseOrActionSelector
                type="cause"
                label={s.rex.newRex.cpsAnalysis.mainCause}
                title={s.rex.newRex.cpsAnalysis.addCause}
                onClick={() => setIsAddCauseModalOpen(true)}
                data={causeSelectionModel?.map((c) => c.id)}
                causeData={causeSelectionModel}
              />
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <CustomCauseOrActionSelector
                type="action"
                label={s.rex.newRex.cpsAnalysis.correcticeActions}
                title={s.rex.newRex.cpsAnalysis.addAction}
                onClick={() => setIsAddActionModalOpen(true)}
                data={actionSelectionModel}
              />
            </Grid>
          </Grid>

          <CustomTextInput
            label={s.rex.newRex.cpsAnalysis.safetyEducation}
            placeholder={s.rex.newRex.cpsAnalysis.safetyEducation}
            multiline
            numberOfLines={3}
            state={[safetyEducation, setSafetyEducation]}
            style={{ marginTop: defaultMargin }}
          />
          {rexType !== ReportType.GoodPractice && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ marginTop: defaultMargin }}
            >
              <Grid item xs={6} style={styles.gridItem}>
                <CustomRiskMatrix
                  label={s.rex.newRex.cpsAnalysis.initialRisk}
                  severitySate={[initialRiskSeverity, setInitialRiskSeverity]}
                  probabilityState={[
                    initialRiskProbability,
                    setInitialRiskProbability,
                  ]}
                />
              </Grid>
              <Grid item xs={6} style={styles.gridItem}>
                <CustomRiskMatrix
                  label={s.rex.newRex.cpsAnalysis.residualRisk}
                  severitySate={[residualRiskSeverity, setResidualRiskSeverity]}
                  probabilityState={[
                    residualRiskProbability,
                    setResidualRiskProbability,
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Section>
        <Divider
          style={{
            backgroundColor: palette.gray200,
            marginTop: largeMargin,
            marginBottom: largeMargin,
          }}
        />
        {/* META DATA */}
        <Section title={s.rex.newRex.metaData.title}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {initialRexData?.id && (
                  <>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.id}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      {initialRexData?.id}
                    </Grid>
                  </>
                )}
                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.rexStatus}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  {initialRexData?.rex_status?.name}
                </Grid>

                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.beaInvestigation}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  {initialRexData?.cresag?.bea_investigation ? 'Oui' : 'Non'}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.creationDate}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  {dateToString(
                    new Date(
                      initialRexData?.created_at || new Date().toISOString()
                    )
                  )}
                </Grid>
                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.createdBy}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  <p style={{ ...styles.metaDataGridValue, margin: 0 }}>
                    {initialRexData?.created_by?.name || user?.name}
                  </p>
                  <p
                    style={{
                      ...styles.metaDataGridValue,
                      margin: 0,
                      marginTop: tinyMargin,
                    }}
                  >
                    {initialRexData?.created_by?.phone || user?.phone}
                  </p>
                  <p
                    style={{
                      ...styles.metaDataGridValue,
                      margin: 0,
                      marginTop: tinyMargin,
                    }}
                  >
                    {initialRexData?.created_by?.email || user?.email}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: defaultMargin }}></Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} style={styles.gridItem}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {initialRexData?.cresag && (
                  <>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.cresagReference}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      {initialRexData?.cresag?.reference}
                    </Grid>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.cresagStatus}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      {initialRexData?.cresag?.event_status}
                    </Grid>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.cresagVersion}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      {initialRexData?.cresag?.version}
                    </Grid>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.cresagCreationDate}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      {dateToString(
                        new Date(
                          initialRexData?.cresag?.created_at ||
                            new Date().toISOString()
                        )
                      )}
                    </Grid>
                    <Grid item xs={5} style={styles.metaDataGridTitle}>
                      {s.rex.newRex.metaData.cresagForm}
                    </Grid>
                    <Grid item xs={7} style={styles.metaDataGridValue}>
                      <p
                        style={{
                          margin: 0,
                          color: palette.darkBlue600,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          navigate(`/cresag/${initialRexData?.cresag?.id}`);
                        }}
                      >
                        {s.rex.newRex.metaData.seeCresagForm}
                      </p>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} style={styles.gridItem}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.updateDate}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  {dateToString(
                    new Date(
                      initialRexData?.updated_at || new Date().toISOString()
                    )
                  )}
                </Grid>
                <Grid item xs={5} style={styles.metaDataGridTitle}>
                  {s.rex.newRex.metaData.updatedBy}
                </Grid>
                <Grid item xs={7} style={styles.metaDataGridValue}>
                  <p style={{ ...styles.metaDataGridValue, margin: 0 }}>
                    {initialRexData?.updated_by?.name || user?.name}
                  </p>
                  <p
                    style={{
                      ...styles.metaDataGridValue,
                      margin: 0,
                      marginTop: tinyMargin,
                    }}
                  >
                    {initialRexData?.updated_by?.phone || user?.phone}
                  </p>
                  <p
                    style={{
                      ...styles.metaDataGridValue,
                      margin: 0,
                      marginTop: tinyMargin,
                    }}
                  >
                    {initialRexData?.updated_by?.email || user?.email}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            style={{
              backgroundColor: palette.gray200,
              marginTop: defaultMargin + smallMargin,
              marginBottom: defaultMargin + smallMargin,
            }}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {clubConcerned && (
              <Grid item xs={6} style={styles.gridItem}>
                <CustomTextInput
                  label={s.rex.newRex.metaData.clubConcerned}
                  placeholder={s.rex.newRex.placeholders.club}
                  state={[clubConcerned, setClubConcerned]}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={6} style={styles.gridItem}>
              <CustomTextInput
                label={s.rex.newRex.metaData.cpsConcerned}
                placeholder={s.rex.newRex.placeholders.cps}
                state={[cpsConcerned, setCpsConcerned]}
                disabled
              />
            </Grid>
            {declarer && (
              <Grid item xs={6} style={styles.gridItem}>
                <CustomTextInput
                  label={s.rex.newRex.metaData.declarer}
                  placeholder={s.rex.newRex.metaData.declarer}
                  state={[declarer, setDeclarer]}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={6} style={styles.gridItem}>
              <CustomDropdown
                label={s.rex.newRex.metaData.rexVisibility}
                placeholder={s.rex.newRex.placeholders.selectValue}
                state={[rexVisibility, setRexVisibility]}
                values={
                  rexVisibilities?.map((visibility) => {
                    return {
                      value: visibility.id,
                      label: `${visibility.visibility} - ${visibility.name}`,
                    };
                  }) || []
                }
              />
            </Grid>
          </Grid>
        </Section>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: largeMargin,
          }}
        >
          {(!initialRexData?.rex_status ||
            initialRexData?.rex_status?.name === RexStatusList.InProgress) && (
            <>
              <CustomButton
                title={s.common.button.completeRex}
                onClick={() => {
                  cresagModalSubmitAction.current = 'submit';
                  setIsConfirmRexVisibilityModalOpen(true);
                }}
                style={{
                  marginRight: 'auto',
                  backgroundColor: palette.violet600,
                  color: palette.white,
                }}
                loading={completeRexLoading}
              />

              <CustomButton
                title={s.common.button.cancel}
                onClick={() => navigate('./..')}
                style={{
                  marginRight: largeMargin,
                  backgroundColor: 'transparent',
                  color: palette.gray600,
                }}
              />
              <CustomButton
                title={s.common.button.cresag}
                onClick={() => {
                  cresagModalSubmitAction.current = 'save';

                  if (initialRexData?.cresag) {
                    onSubmit().then(() => {
                      navigate(`/cresag/${initialRexData?.cresag?.id}`);
                    });
                    return;
                  }

                  setIsCresagIdentificationModalOpen(true);
                }}
                style={{
                  marginRight: defaultMargin,
                }}
                loading={completeRexLoading}
              />
              <CustomButton
                title={s.common.button.saveUpdates}
                onClick={() => {
                  if (!initialRexData?.id) {
                    cresagModalSubmitAction.current = 'save';
                    setIsCresagIdentificationModalOpen(true);
                    return;
                  }

                  setSaveLoading(true);
                  onSubmit()
                    .then(() => {
                      navigate('./..');
                    })
                    .finally(() => {
                      setSaveLoading(false);
                    });
                }}
                loading={saveLoading}
              />
            </>
          )}
          {initialRexData?.rex_status?.name === RexStatusList.Ready && (
            <>
              <CustomButton
                title={s.common.button.editRex}
                onClick={() => {
                  if (initialRexData?.id) {
                    setCompleteRexLoading(true);
                    reopenRex
                      .mutateAsync(initialRexData?.id)
                      .then(() => {
                        window.location.reload();
                      })
                      .finally(() => {
                        setCompleteRexLoading(false);
                      });
                  }
                }}
                style={{
                  marginRight: 'auto',
                  backgroundColor: palette.violet600,
                  color: palette.white,
                }}
                loading={completeRexLoading}
              />
              <CustomButton
                title={s.common.button.back}
                onClick={() => navigate('./..')}
                style={{
                  marginRight: largeMargin,
                  backgroundColor: 'transparent',
                  color: palette.gray600,
                }}
              />
            </>
          )}
        </div>
      </div>
      <ConfirmRexVisibilityModal
        open={isConfirmRexVisibilityModalOpen}
        onClose={() => {
          setIsConfirmRexVisibilityModalOpen(false);
        }}
        onSubmit={() => {
          if (initialRexData?.cresag?.id) {
            setCompleteRexLoading(true);
            onSubmit()
              .then((rexId) => {
                completeRex.mutateAsync(rexId).then(() => {
                  navigate('./..');
                });
              })
              .finally(() => {
                setCompleteRexLoading(false);
              });
          } else {
            setIsCresagIdentificationModalOpen(true);
          }
        }}
        visibiliyState={[rexVisibility, setRexVisibility]}
        rexVisibilities={rexVisibilities}
      />
      <CresagIdentificationModal
        open={isCresagIdentificationModalOpen}
        onClose={() => setIsCresagIdentificationModalOpen(false)}
        onSubmit={(needCresag: boolean) => {
          return onSubmit().then((rexId: number) => {
            if (needCresag) {
              api
                .post(`cresag`, {
                  rex_id: rexId,
                })
                .then(async ({ data }) => {
                  if (cresagModalSubmitAction.current === 'submit') {
                    await completeRex.mutateAsync(rexId).then(() => {
                      navigate(`/cresag/${data?.id}`);
                    });
                    return;
                  }

                  if (cresagModalSubmitAction.current === 'save') {
                    setSaveLoading(true);
                    await onSubmit()
                      .then(() => {
                        navigate(`/cresag/${data?.id}`);
                      })
                      .finally(() => {
                        setSaveLoading(false);
                      });
                  }
                });
            } else {
              if (cresagModalSubmitAction.current === 'submit') {
                completeRex.mutateAsync(rexId).then(() => {
                  navigate('./..');
                });
                return;
              }

              if (cresagModalSubmitAction.current === 'save') {
                setSaveLoading(true);
                onSubmit()
                  .then(() => {
                    navigate('./..');
                  })
                  .finally(() => {
                    setSaveLoading(false);
                  });
              }
            }
          });
        }}
      />
      <AddCauseModal
        open={isAddCauseModalOpen}
        onClose={() => setIsAddCauseModalOpen(false)}
        rowSelectionState={[causeSelectionModel, setCauseSelectionModel]}
        // causeDataState={[causeSelectionData, setCauseSelectionData]}
      />
      <AddActionModal
        open={isAddActionModalOpen}
        onClose={() => setIsAddActionModalOpen(false)}
        rowSelectionState={[actionSelectionModel, setActionSelectionModel]}
      />
    </>
  );
};

interface SectionProps {
  title: string;
  children?: any;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <>
      <p style={{ ...fonts.bold18, margin: 0 }}>{title}</p>
      <div
        style={{
          marginTop: defaultMargin,
          marginLeft: smallMargin,
          marginRight: smallMargin,
        }}
      >
        {children}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  metaDataGridTitle: {
    ...fonts.regular14,
    color: palette.gray700,
  },
  metaDataGridValue: {
    ...fonts.semiBold14,
  },
};
